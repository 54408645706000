import { TipoFile } from "../utils/enum";

export default class CanzoneModel {
    titolo: string;
    id: string;
    idUtenteCreazione: any;
    autore: string;
    testo: string;
    partiDellaMessa: string;
    tempiLiturgici: string;
    paroleChiave: string;   
    hasAllegatiDoc: string;
    allegati: any;
    hasAllegatiAudio: string;
    visibilityPolicy: any;
    urlModifica: string;
    urlDettaglio: string;
    constructor(_titolo: string, _id: string, _idUtenteCreazione: any, _autore: string, _testo: string, _partiDellaMessa: string, _tempiLiturgici: string, _paroleChiave: string, _visibilityPolicy: number, _allegati: any = null) {
        this.titolo = _titolo;
        this.id = _id;
        this.idUtenteCreazione = _idUtenteCreazione;
        this.autore = _autore;
        this.testo = _testo;
        this.partiDellaMessa = _partiDellaMessa;
        this.tempiLiturgici = _tempiLiturgici;
        this.paroleChiave = _paroleChiave;
        this.visibilityPolicy = _visibilityPolicy;
        if (_allegati != null && _allegati.length > 0) {
            this.allegati = _allegati;
            this.hasAllegatiDoc = _allegati.some((e: any) => e.tipoFile == TipoFile.Documento);
            this.hasAllegatiAudio = _allegati.some((e: any) => e.tipoFile == TipoFile.Audio);
        }
        else {
            this.hasAllegatiDoc = "false";
            this.hasAllegatiAudio = "false";
        }
        this.urlModifica = "/modificacanzone/" + _id;
        this.urlDettaglio = "/dettagliocanzone/" + _id;
    }
}